<template>
<div class="navBar">
  <div class="navBarlist">
    <div class="title">{{ name }}</div>
    <div @click="tabclick(item, index)" :class="[router ? 'routeritem' : 'tabItem', (act == item.url || act == index) && router ? 'act tabs' : (act == item.url || act == index) && !router ? 'act' : '']"
      v-for="(item, index) in list" :key="index">{{ item.name }}</div>
  </div>
</div>
</template>
<script>
export default {
  name: 'secondaryNavigation',
  props: {
    list: {
      type: Array,
      default () {
        return [];
      }
    },
    name: {
      type: String,
      default: '营销中心'
    },
    router: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  watch: {
    $route: {
      handler(to) {
        if (this.router) {
          this.act = to.path;
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      act: ''
    };
  },
  methods: {
    tabclick(item) {
      if (item.url == this.act) {
        return;
      }
      this.act = item.url;
      this.$router.push({
        path: item.url
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.navBar {
  width: 100%;
  height: 57px;
  margin-bottom: 20px;
  font-size: 12px;
  border-bottom: 1px solid #dedede;
  position: sticky;
  box-sizing: border-box;
  user-select: none;
  background-color: #FFFFFF;
  .label {
    width: 100%;
    padding-left: 40px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin: 21px 0 14px;
  }

  .navBarlist {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    bottom: -1px;
    .title {
      min-width: 140px;
      font-size: 14px;
      color: #333333;
      text-align: center;
      margin-bottom: 7px;
    }
    .routeritem {
      padding: 0 10px;
      height: 37px;
      text-align: center;
      line-height: 32px;
      border: 1px solid #dedede;
      z-index: 9;
      border-left: none;
      box-sizing: border-box;
      background: #ffffff;
      cursor: pointer;

      &:nth-child(2) {
        border-left: 1px solid #dedede;
      }

      &:last-child {
        border-right: 1px solid #dedede;
      }
    }

    .tabItem {
      padding: 0 10px;
      height: 37px;
      text-align: center;
      line-height: 32px;
      z-index: 9;
      border-left: none;
      border-bottom: 1px solid #dedede;
      box-sizing: border-box;
      background: #ffffff;
      cursor: pointer;
    }
    .act {
      color: #3d7eff;
      border-bottom: none;
      border-top: 2px solid #3d7eff;
      border-right: 1px solid #dedede;
      border-left: 1px solid #dedede;
    }
    .tabs {
      border-left: none;
    }
  }
}
</style>
